/* eslint max-lines: ["error", 500]*/
import "core-js/stable";
import "regenerator-runtime/runtime";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

$('.c-index-visual__slider').slick({
	autoplay: true,
	autoplaySpeed: 5000,
	dots: true,
	arrows: false,
	slidesToShow: 1,
	speed: 600
});

// Selectbox
$(window).on("load resize", function () {
  if (window.isSP()) {
    $(".c-index-newsHead__pulldown").css({ display: "none" });
  } else {
    $(".c-index-newsHead__pulldown").css({ display: "flex" });
  }
});

$(function () {
  $("div.c-index-newsHead__category").each(function () {
    const self = $(this),
      select = $("a.c-index-newsHead__select", self),
      pulldown = $("div.c-index-newsHead__pulldown", self),
      data = $("input:hidden", self),
      select_value = $(".__text", select);

    select.click(function (e) {
      e.preventDefault();
      if (window.isSP()) {
        pulldown.slideToggle();
        $(this).toggleClass("_select-focus_");
        e.stopPropagation();
      }
    });

    $("a", pulldown).click(function () {
      const value = $(this).attr("href").replace("#", ""),
        text = $(this).text();

      select_value.text(text);
      data.val(value);

      $("a._selected_", pulldown).removeClass("_selected_");
      $("a._select-focus_").removeClass("_select-focus_");
      $(this).addClass("_selected_");
      if (window.isSP()) {
        pulldown.hide();
      }
    });

    $("body").click(function () {
      if (window.isSP()) {
        $("a._select-focus_").removeClass("_select-focus");
        $("div.c-index-newsHead__pulldown").hide();
      }
    });
  });
});

// News
(function ($) {
  const $filters = $(".c-index-newsHead__filter [data-filter]");
  let currentCategory = "";

  $filters.on("click", function (e) {
    e.preventDefault();
    const $this = $(this);

    $filters.removeClass("_active_");
    $this.addClass("_active_");

    const $content = $(".news__content [data-category]"),
      filterCategory = $this.attr("data-filter");
    currentCategory = filterCategory;

    const $target =
      filterCategory === "all"
        ? $content.filter(":lt(8)")
        : $content.filter(`[data-category = "${filterCategory}"]:lt(8)`);

    $content
      .stop()
      .fadeOut()
      .promise()
      .done(function () {
        if (currentCategory === filterCategory) {
          $target.fadeIn();
        }
      });
  });
})(jQuery);

/* eslint max-lines-per-function: ["error", 200]*/
$(function () {
  const getBlogJson = function (json) {
      const d = new $.Deferred();
      $.ajax({
        type: "get",
        url: json,
        dataType: "json",
        success(_data) {
          d.resolve(_data);
        },
        error() {
          d.resolve([]);
        },
      });
      return d.promise();
    },
    time2Date = function (str) {
      return str.slice(0, 10).replace(/-/gu, "/");
    },
    createReportList = function (data) {
      const block = $("#news-pic-list"),
        list = block.find(".news__content");
      window._.each(data, function (obj, i) {
        const { title } = obj,
          url = obj.permalink,
          target = obj.blank ? ' target="_blank"' : "",
          category = obj.category_class,
          { thumbnail } = obj,
          datetime = obj.created,
          date = time2Date(obj.created),
          label1 = `<div class="e-label _h-s_ _${obj.category_class}_ __label">${obj.category}</div>`,
          label2 = obj.club_category
            ? `<p class="__club">${obj.club_category}</p>`
            : "",
          display = i > 7 ? 'style="display: none"' : "";
        list.append(
          $(
            `<a href="${url}"${target} class="panel c-news-list__item __col" data-category="${category}"${display}>
<div class="c-news-list__img">
<img src="${thumbnail}" alt="${title}" class="__elem">
</div>
<div class="c-news-list__body">
${label1}
<time datetime="${datetime}" class="__date">${date}</time>
${label2}
<p class="__text">${title}</p>
</div>
</a>`
          )
        );
      });
    };

  const ver = Math.random().toString(32).substring(2);
  $.when(getBlogJson(`/news/mt_news_list.json?${ver}`)).done(function (data1) {
    createReportList(data1);
  });
});
